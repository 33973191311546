<template>
    <section class="authentication authentication-container">
        <div class="authentication-container--login_form">
            <slot />
            <login-form-description
                v-if="false"
            />
        </div>
        <slot name="modals" />
    </section>
</template>

<script>
import LoginFormDescription from '@/components/common/entering-form-layout/LoginFormDescription.vue';

export default {
    name: 'EnteringFormLayout',
    components: {
        LoginFormDescription
    }
}
</script>
