<template>
    <div class="form-block-_description">
        <span />
    </div>
</template>

<script>
export default {
    name: 'LoginFormDescription'
}
</script>
